import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/image1.jpg'
import IMG2 from '../../assets/image2.jpg'
import IMG3 from '../../assets/image3.jpg'
import IMG4 from '../../assets/portfolio6.jpg'
import IMG5 from '../../assets/image5.jpg'
import IMG6 from '../../assets/image6.jpg'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
      <article className='portfolio__item'>
      <div className="portfolio__item-image">
        <img src={IMG1} alt="" />
      </div>
      <h3>Islamic School Website Development</h3>
      <div className="portfolio__item-cta">
      <a href="https://rtmabone.com/" className='btn btn-primary' target='_blank'>Visit Site</a>
      </div>
      </article>
      <article className='portfolio__item'>
      <div className="portfolio__item-image">
        <img src={IMG2} alt="" />
      </div>
      <h3>Influencer Website Development</h3>
      <div className="portfolio__item-cta">
      <a href="https://radinalridwan.com/" className='btn btn-primary' target='_blank'>Visit Site</a>
      </div>
      </article>
      <article className='portfolio__item'>
      <div className="portfolio__item-image">
        <img src={IMG3} alt="" />
      </div>
      <h3>Course Institute Website Development</h3>
      <div className="portfolio__item-cta">
      <a href="https://adzkiyaprofesional.com/" className='btn btn-primary' target='_blank'>Visit Site</a>
      </div>
      </article>
      <article className='portfolio__item'>
      <div className="portfolio__item-image">
        <img src={IMG4} alt="" />
      </div>
      <h3>Pinterest Account Managemenet</h3>
      <div className="portfolio__item-cta">
      <a href="https://id.pinterest.com/canvadesigntemplate/" className='btn btn-primary' target='_blank'>Visit Site</a>
      </div>
      </article>
      <article className='portfolio__item'>
      <div className="portfolio__item-image">
        <img src={IMG5} alt="" />
      </div>
      <h3>Instagram Account Management</h3>
      <div className="portfolio__item-cta">
      <a href="https://www.instagram.com/radinalridwan/" className='btn btn-primary' target='_blank'>Visit Site</a>
      </div>
      </article>
      <article className='portfolio__item'>
      <div className="portfolio__item-image">
        <img src={IMG6} alt="" />
      </div>
      <h3>Community School Website Development</h3>
      <div className="portfolio__item-cta">
      <a href="https://pkbmafizahprofesional.id/" className='btn btn-primary' target='_blank'>Visit Site</a>
      </div>
      </article>
      </div>
    </section>
  )
}

export default Portfolio