import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Content Development</h3>
          <div className="experience__content">
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
             <div>
             <h4>SEO Research</h4>
              <small className= 'text-light'>Experienced</small>
             </div>
            </article>
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Creative Writing</h4>
              <small className= 'text-light'>Experienced</small>
              </div>
            </article>
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Social Media</h4>
              <small className= 'text-light'>Experienced</small>
              </div>
            </article>
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Editing and Proofreading</h4>
              <small className= 'text-light'>Experienced</small>
              </div>
            </article>
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Email Marketing</h4>
              <small className= 'text-light'>Experienced</small>
              </div>
            </article>
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Research and Fact-checking</h4>
              <small className= 'text-light'>Experienced</small>
              </div>
            </article>
          </div>
        </div>
        {/* END OF FRONTEND*/}

        <div className="experience__backend">
        <h3>Web Development</h3>
        <div className="experience__content">
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>WordPress</h4>
              <small className= 'text-light'>Experienced</small>
              </div>
            </article>
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>HTML</h4>
              <small className= 'text-light'>Beginner</small>
              </div>
            </article>
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>CSS</h4>
              <small className= 'text-light'>Beginner</small>
              </div>
            </article>
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
             <div>
             <h4>JavaScripts</h4>
              <small className= 'text-light'>Beginner</small>
             </div>
            </article>
            <article className= 'experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>React Js</h4>
              <small className= 'text-light'>Beginner</small>
              </div>
            </article>
          </div>

        </div>
      </div>
      </section>
  )
}

export default Experience