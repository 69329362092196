import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.png'
import AVTR2 from '../../assets/avatar2.png'
import AVTR3 from '../../assets/avatar3.png'
import AVTR4 from '../../assets/avatar4.png'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
    {
      avatar: AVTR1,
      name: 'Radinal Ridwan',
      review: "Bismillah, Alhamdulillah, Rachmat Ramadhan Website Creation Services are of good quality, worth the price, professionalism and responsiveness. Yesterday's project only took a few days for the website to be finished. Highly Recommended for Friends of Businesspeople, Small and Medium Enterprises, and Digital Creators in Creating Websites. Enjoy your work!."
    },
    {
      avatar: AVTR2,
      name: 'Suharjun Abdullah',
      review: 'I am very satisfied with the website creation services. The website concept and design is cool, cool and professional. The Most Memorable Ones Are Made According to Our Tastes. Revised Until We Are Satisfied With The Results. Great..🤩 My advice if you want to create a website. I Recommend This Place. In Shaa Allah the results will be satisfactory. 🙏😍.'
    },
    {
      avatar: AVTR3,
      name: 'Calista Ikeme',
      review: 'Awesome this is great, thanks Rachmat Ramadhan!.'
    },
    {
      avatar: AVTR4,
      name: 'Kevin Carter',
      review: 'Thanks for the article.'
    },
  ]


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar}/>
              </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials