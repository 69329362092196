import React from 'react'

const CTA = () => {
  return (
    <div className= 'cta'>
        <a href= "#services" className= 'btn'>My Services</a>
        <a href= "#contact" className= 'btn btn-primary'>Let's Talk</a>
    </div>
  )
}

export default CTA